import React from "react";

import { withFirebase } from "../Firebase/context";

const LogOutButton = ({ firebase }) => (
  <button
    type="button"
    className="button is-light"
    onClick={() =>
      firebase.doSignOut().then(() => alert("Signed Out Successfully"))
    }
  >
    Log Out
  </button>
);

export default withFirebase(LogOutButton);
