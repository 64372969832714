import React from "react";

const NotFound = () => (
  <div>
    <React.Fragment>
      <h2>
        Error 404 : Content Not Found! Please proceed back to the main page.
      </h2>
      <button onClick={window.history.back()}>Go Back</button>
    </React.Fragment>
  </div>
);

export default NotFound;
