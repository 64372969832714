import React from "react";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import PropTypes from "prop-types";

class QuizPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quizzes: [],
      quizzesData: {},
      selectedQuiz: "",
      showSpinner: true,
      currentPage: 1,
      pages: 1
    };
  }

  componentDidMount() {
    const Quizzes = {};
    let countQuizzes = 0;
    this.props.firebase
      .getAllQuizzes()
      .then(snapshot => {
        snapshot.forEach(quiz => {
          Quizzes[quiz.id] = quiz.data();
          countQuizzes++;
        });
        this.setState({
          quizzes: Object.keys(Quizzes),
          quizzesData: Quizzes,
          selectedQuiz: Object.keys(Quizzes)[0],
          showSpinner: false,
          pages: Math.ceil(countQuizzes / 12)
        });
      })
      .catch(err => {
        console.log("Error getting quizzes", err);
      });
  }

  static propTypes = {
    history: PropTypes.object
  };

  handleChange = selectedQuiz =>
    this.setState({ selectedQuiz: selectedQuiz.value });

  goToQuiz = event => {
    // 1. Stop the form from submitting
    event.preventDefault();
    // 2. get the selected quiz from the state
    const quizId = this.state.selectedQuiz;
    // 3. Change the page to /quiz/whatever-they-entered
    this.props.history.push(`/quiz/${quizId}`);
  };

  generateAvailableQuizBox = (quiz, index) => {
    const data = this.state.quizzesData[quiz];
    return (
      <div
        key={index}
        className="column is-half-tablet
      is-one-third-desktop
      is-one-third-widescreen
      is-one-quarter-fullhd"
      >
        <div className="card card-equal-height">
          <div className="card-image">
            <figure className="image is-square">
              <img src={data.image} alt={quiz} />
            </figure>
          </div>
          <div className="card-header">
            <div className="card-header-title">{data.quizName}</div>
          </div>
          <div className="card-content">
            <p>{data.description}</p>
          </div>
          <div className="card-footer">
            <div className="card-footer-item">
              समय सीमा :
              {data.timeLimit < 101
                ? ` ${data.timeLimit} सेकंड`
                : ` ${Math.floor(data.timeLimit / 60)} मिनट`}
            </div>
            <div className="card-footer-item">
              {` ${data.questions.length} प्रश्न`}
            </div>
            <div className="card-footer-item has-background-primary">
              <button
                className="button is-primary is-rounded"
                onClick={() => {
                  this.setState({ selectedQuiz: quiz });
                  this.props.history.push(`/quiz/${quiz}`);
                }}
              >
                शुरू करें
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const availableQuizzes = this.state.quizzes.map(quiz => ({
      value: quiz,
      label: this.state.quizzesData[quiz].quizName
    }));
    return (
      <React.Fragment>
        <section className="section">
          <form onSubmit={this.goToQuiz}>
            <h3 className="is-size-2 has-text-black has-text-centered has-text-weight-semibold">
              अपना टेस्ट चुनें
            </h3>
            <div className="columns">
              <div className="column">
                <div className="quiz-picker is-9">
                  <Select
                    onChange={this.handleChange}
                    options={availableQuizzes}
                    noOptionsMessage={() =>
                      "Sorry! No test found for your search term"
                    }
                    required
                  >
                    {availableQuizzes}
                  </Select>
                </div>
              </div>
              <div className="column is-3">
                <button
                  className="button is-centered is-rounded is-secondary is-medium"
                  type="submit"
                >
                  टेस्ट शुरू करें
                </button>
              </div>
            </div>
          </form>
        </section>
        <section className="section">
          <div className="title">
            <h2 className="is-size-2 has-text-black has-text-weight-semibold">
              सभी उपलब्ध टेस्ट
            </h2>
          </div>
          {this.state.showSpinner ? (
            <div
              className="section"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="loader-inner ball-scale-multiple">
                <div style={{ backgroundColor: "#1bbc9c" }} />
                <div style={{ backgroundColor: "#1bbc9c" }} />
                <div style={{ backgroundColor: "#1bbc9c" }} />
              </div>
            </div>
          ) : (
            <div className="columns is-multiline">
              {this.state.quizzes
                .slice(
                  this.state.currentPage * 12 - 12,
                  this.state.currentPage * 12
                )
                .map((quiz, index) =>
                  this.generateAvailableQuizBox(quiz, index)
                )}
            </div>
          )}
        </section>
        <nav
          className="pagination is-rounded is-centered"
          role="navigation"
          aria-label="pagination"
        >
          <button
            onClick={() => {
              if (this.state.currentPage > 1)
                this.setState({ currentPage: this.state.currentPage - 1 });
            }}
            className="pagination-previous"
          >
            Previous
          </button>
          <button
            onClick={() => {
              if (this.state.currentPage < this.state.pages)
                this.setState({ currentPage: this.state.currentPage + 1 });
            }}
            className="pagination-next"
          >
            Next page
          </button>
          <ul className="pagination-list">
            <li>
              <button
                onClick={() => this.setState({ currentPage: 1 })}
                className="pagination-link"
                aria-label="Go to page 1"
              >
                1
              </button>
            </li>
            <li>
              <span className="pagination-ellipsis">&hellip;</span>
            </li>
            {this.state.currentPage !== 1 &&
              this.state.currentPage !== this.state.pages && (
                <li>
                  <button
                    className="pagination-link is-current"
                    aria-label={`Page ${this.state.currentPage}`}
                    aria-current="page"
                  >
                    {this.state.currentPage}
                  </button>
                </li>
              )}
            <li>
              <span className="pagination-ellipsis">&hellip;</span>
            </li>
            <li>
              <button
                onClick={() => this.setState({ currentPage: this.state.pages })}
                className="pagination-link"
                aria-label={`Go to page ${this.state.pages}`}
              >
                {this.state.pages}
              </button>
            </li>
          </ul>
        </nav>
      </React.Fragment>
    );
  }
}

export default withRouter(QuizPicker);
