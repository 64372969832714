import React from "react";
import swal from "@sweetalert/with-react";
import PropTypes from "prop-types";

class Footer extends React.Component {
  renderQuestionMatrix = () => {
    const questionMatrix = this.props.userAnswers.map((answer, index) => {
      return (
        <div
          key={index}
          className={
            answer === -1 ? "column is-2 skipped" : "column is-2 attempted"
          }
          onClick={() => this.props.goToQuestion(index)}
        >
          Q{index + 1}
        </div>
      );
    });
    return questionMatrix;
  };
  nextQuestion = () => {
    const nextQuestionIndex = this.props.currentQuestionIndex + 1;
    this.props.goToQuestion(nextQuestionIndex);
  };
  previousQuestion = () => {
    const previousQuestionIndex = this.props.currentQuestionIndex - 1;
    this.props.goToQuestion(previousQuestionIndex);
  };
  render() {
    const { totalQuestions, currentQuestionIndex } = this.props;
    return (
      <section className="container is-fixed-bottom">
        <div className="columns is-centered is-mobile is-multiline ordered-mobile">
          <div className="column is-one-fourth-tablet is-half-mobile is-narrow fl-1">
            {currentQuestionIndex > 0 ? (
              <button
                className="button is-primary is-outlined"
                onClick={this.previousQuestion}
              >
                पिछला प्रश्न
              </button>
            ) : (
              <button className="button" disabled>
                पिछला प्रश्न
              </button>
            )}
          </div>
          <div className="column is-one-fourth-tablet is-half-mobile is-narrow fl-3">
            <button
              className="button is-primary is-outlined"
              onClick={() =>
                swal({
                  title: "प्रश्न पर जायें",
                  content: (
                    <div className="question-matrix columns is-multiline is-mobile">
                      {this.renderQuestionMatrix()}
                    </div>
                  ),
                  buttons: {
                    cancel: "Close"
                  }
                })
              }
            >
              प्रश्न पर जायें
            </button>
          </div>
          <div className="column is-one-fourth-tablet is-half-mobile is-narrow fl-4">
            <button
              className={
                currentQuestionIndex < totalQuestions - 1
                  ? "button is-primary is-outlined"
                  : "button is-secondary"
              }
              onClick={() =>
                swal({
                  icon: "warning",
                  title: "टेस्ट जमा करें",
                  content: (
                    <div>
                      क्या आप टेस्ट सबमिट करना चाहते हैं ? कृपया सुनिश्चित करें
                      कि आप सभी प्रश्नों से गुज़र चुके हैं।
                    </div>
                  ),
                  buttons: {
                    cancel: true,
                    confirm: true
                  }
                }).then(isConfirm => {
                  if (isConfirm) {
                    this.props.submitQuiz();
                  }
                })
              }
            >
              सबमिट टेस्ट
            </button>
          </div>
          <div className="column is-one-fourth-tablet is-half-mobile is-narrow fl-2">
            {currentQuestionIndex < totalQuestions - 1 ? (
              <button
                className="button is-secondary"
                onClick={this.nextQuestion}
              >
                अगला प्रश्न
              </button>
            ) : (
              <button className="button" onClick={this.nextQuestion} disabled>
                अगला प्रश्न
              </button>
            )}
          </div>
        </div>
      </section>
    );
  }
}

Footer.propTypes = {
  currentQuestionIndex: PropTypes.number.isRequired,
  totalQuestions: PropTypes.number.isRequired,
  submitQuiz: PropTypes.func.isRequired,
  goToQuestion: PropTypes.func.isRequired
};

export default Footer;
