import React from "react";
import QuizPicker from "./QuizPicker";
import Layout from "../Layout";
import { withFirebase } from "../Firebase/context";

const Home = () => (
  <Layout>
    <QuizPickerComponent />
    <footer className="footer has-background-black">
      <div className="content has-text-centered has-text-light is-small">
        <p>
          <a href="https://sugamgyan.com/privacy-policy/"> प्राइवेसी पॉलिसी </a>
          |
          <a href="https://sugamgyan.com/terms-and-conditions/">
            {" "}
            नियम एवं शर्तें{" "}
          </a>
          |<a href="https://sugamgyan.com/contact-us/"> सम्पर्क सूत्र </a>
        </p>
        <p>
          <a href="https://sugamgyan.com"> सुगम ज्ञान </a>© कॉपीराइट 2016-2019 ।
          सर्वाधिकार सुरक्षित
        </p>
      </div>
    </footer>
  </Layout>
);

const QuizPickerComponent = withFirebase(QuizPicker);

export default Home;
