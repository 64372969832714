import React from "react";
import { withFirebase } from "../../Firebase/context";
import NewQuestion from "./NewQuestion";
import swal from "@sweetalert/with-react";
import Layout from "../../Layout";

class NewQuizBase extends React.Component {
  constructor() {
    super();
    this.state = {
      questions: [{ correct: 0, question: "", options: ["", "", "", ""] }],
      quizName: "",
      image: "",
      description: "Quiz Description",
      timeLimit: 0
    };
  }
  addQuestion = e => {
    e.preventDefault();
    this.setState(prevState => ({
      questions: [
        ...prevState.questions,
        { correct: 0, question: "", options: ["", "", "", ""] }
      ]
    }));
  };
  removeQuestion = e => {
    e.preventDefault();
    const questions = this.state.questions;
    const idx = e.target.dataset.idx;
    questions.splice(idx, 1);
    this.setState({
      questions
    });
  };

  addOption = e => {
    e.preventDefault();
    const questions = this.state.questions;
    const idx = e.target.dataset.idx;
    questions[idx].options.push("");
    this.setState({
      questions
    });
  };

  removeOption = e => {
    e.preventDefault();
    const questions = this.state.questions;
    const idx = e.target.dataset.idx;
    const qindex = e.target.dataset.qindex;
    questions[qindex].options.splice(idx, 1);
    this.setState({
      questions
    });
  };

  handleChange = e => {
    if ("option" === e.target.dataset.type) {
      let questions = [...this.state.questions];
      questions[e.target.dataset.qindex].options[e.target.dataset.id] =
        e.target.value;
      this.setState({ questions });
    } else if (["question", "correct"].includes(e.target.dataset.type)) {
      let questions = [...this.state.questions];
      questions[e.target.dataset.id][e.target.dataset.type] = e.target.value;
      this.setState({ questions });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.firebase
      .addQuiz({ ...this.state })
      .then(addedQuizRef => {
        swal({
          icon: "success",
          title: "Quiz Saved Successfully",
          content: (
            <div>
              Quiz has been saved successfully in the database. Press Confirm to
              go to All Quizzes Page or press Cancel to add a New Quiz.
            </div>
          ),
          buttons: {
            cancel: true,
            confirm: true
          }
        }).then(value => {
          console.log(value);
          if (value === true) this.props.history.push("/admin/quizzes");
          else
            this.setState({
              questions: [{ correct: 0, question: "", options: [""] }],
              quizName: "",
              image: "",
              description: "Quiz Description",
              timeLimit: 0
            });
        });
      })
      .catch(error => {
        swal({
          icon: "error",
          title: "There has been an error",
          content: (
            <div>
              The Quiz could not be saved successfully in the database. Please
              check db connection.
            </div>
          ),
          buttons: {
            cancel: false,
            confirm: true
          }
        });
        console.error("Error adding Quiz: ", error);
      });
  };

  render() {
    const { questions, quizName, image, description, timeLimit } = this.state;
    return (
      <Layout>
        <section className="section">
          <form className="container">
            <div className="field">
              <label htmlFor="quizName" className="label">
                Quiz Name
              </label>
              <div className="control">
                <input
                  type="text"
                  name="quizName"
                  id={quizName}
                  placeholder="Quiz Name"
                  value={quizName}
                  className="input is-rounded"
                  onChange={this.handleChange}
                />
              </div>
              <p className="help">Keep the quiz Name short and unique.</p>
            </div>
            <div className="field">
              <label htmlFor="image" className="label">
                Image URL
              </label>
              <div className="control">
                <input
                  type="url"
                  name="image"
                  placeholder="Image URL"
                  id={image}
                  value={image}
                  className="input is-rounded"
                  onChange={this.handleChange}
                />
              </div>
              <p className="help">
                Upload images{" "}
                <a
                  href="https://imgbb.com/upload"
                  target="_blank"
                  rel="noopener nofollow noreferrer"
                >
                  here
                </a>
                , and paste the image src above. Image should be
                square(recommended: 300px).
              </p>
            </div>
            <div className="field">
              <label htmlFor="description" className="label">
                Quiz Description
              </label>
              <div className="control">
                <textarea
                  name="description"
                  value={description}
                  id={description}
                  className="textarea"
                  onChange={this.handleChange}
                >
                  {description}
                </textarea>
              </div>
              <p className="help">Describe what the quiz is about.</p>
            </div>
            <div className="field">
              <label htmlFor="timeLimit" className="label">
                Time Limit
              </label>
              <div className="control">
                <input
                  type="number"
                  name="timeLimit"
                  placeholder="Time Limit"
                  id={timeLimit}
                  value={timeLimit}
                  className="input is-rounded"
                  onChange={this.handleChange}
                />
              </div>
              <p className="help">Please enter the time limit in seconds.</p>
            </div>
            <div className="subtitle">Questions:</div>
            <NewQuestion
              questions={questions}
              handleQuestionChange={this.handleChange}
              addOption={this.addOption}
              removeQuestion={this.removeQuestion}
              removeOption={this.removeOption}
            />
            <div className="field is-grouped is-grouped-centered">
              <p className="control">
                <button onClick={this.addQuestion} className="button is-info">
                  Add New Question
                </button>
              </p>
              <p className="control">
                <button
                  onClick={this.handleSubmit}
                  type="submit"
                  className="button is-success"
                >
                  Save Quiz
                </button>
              </p>
            </div>
          </form>
        </section>
      </Layout>
    );
  }
}

const NewQuiz = withFirebase(NewQuizBase);

export default NewQuiz;
