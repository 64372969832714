import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const settings = { timestampsInSnapshots: true };
const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    app.firestore().settings(settings);
    this.auth = app.auth();
    this.db = app.firestore();
  }

  // *** Auth API ***
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);
  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);
  doSignOut = () => this.auth.signOut();
  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);
  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  // *** Database API ***

  //Quizzes
  getAllQuizzes = () =>
    this.db
      .collection("quizzes")
      .orderBy("quizName")
      .get();
  getQuiz = quizId =>
    this.db
      .collection("quizzes")
      .doc(quizId)
      .get();
  addQuiz = quizData => this.db.collection("quizzes").add(quizData);
  updateQuiz = (quizId, quizData) =>
    this.db
      .collection("quizzes")
      .doc(quizId)
      .set(quizData);
  deleteQuiz = quizId =>
    this.db
      .collection("quizzes")
      .doc(quizId)
      .delete();

  // Users
  getAllUsers = () => this.db.collection("users").get();
  getUser = userId =>
    this.db
      .collection("users")
      .doc(userId)
      .get();
  addUser = (userId, userData) =>
    this.db
      .collection("users")
      .doc(userId)
      .add(userData);
  updateUser = (userId, userData) =>
    this.db
      .collection("users")
      .doc(userId)
      .set(userData);
  deleteUser = userId =>
    this.db
      .collection("users")
      .doc(userId)
      .delete();
}

export default Firebase;
