import React from "react";
import { Link, withRouter } from "react-router-dom";
import { withFirebase } from "../Firebase/context";
import swal from "@sweetalert/with-react";

const PasswordForgetPage = () => (
  <div id="login">
    <div className="login-card">
      <div className="card-title">
        <h1>Forget your Password ?</h1>
      </div>
      <div className="content">
        <PasswordForgetForm />
      </div>
      <div>
        <Link to="/account/register">Don't have an account ?</Link>
        <Link to="/account/login">Login</Link>
      </div>
    </div>
  </div>
);

const INITIAL_STATE = {
  email: "",
  error: null
};

class PasswordForgetFormBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        swal({
          icon: "success",
          title: "Password Reset Successfully!",
          content: (
            <div>
              Password Reset Mail has been sent successfully to your email
              address. Press yes to go back to homepage.
            </div>
          ),
          buttons: {
            cancel: true,
            confirm: true
          }
        }).then(val => {
          val
            ? this.props.history.push("/")
            : this.setState({ ...INITIAL_STATE });
        });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;
    const isInvalid = email === "";

    return (
      <form onSubmit={this.onSubmit}>
        <div className="field">
          <p className="control has-icons-left has-icons-right">
            <input
              name="email"
              className="input"
              type="email"
              value={this.state.email}
              onChange={this.onChange}
              placeholder="Email Address"
            />
            <span className="icon is-small is-left">
              <i className="fas fa-envelope" />
            </span>
            <span className="icon is-small is-right">
              <i className="fas fa-check" />
            </span>
          </p>
        </div>
        <button disabled={isInvalid} type="submit">
          Reset My Password
        </button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

export default PasswordForgetPage;

const PasswordForgetForm = withRouter(withFirebase(PasswordForgetFormBase));

export { PasswordForgetForm };
