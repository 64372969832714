import React from "react";
import PropTypes from "prop-types";

class Question extends React.Component {
  render() {
    const { currentQuestion, optionSelected, handleOptionClick } = this.props;
    return (
      <div className="container">
        <div className="question content">
          <div className="question-description is-size-5">
            {currentQuestion.question}
          </div>
          <ol className="question-options" type="A">
            {currentQuestion.options.map((option, index) => {
              const classes =
                optionSelected === index
                  ? "question-option selected"
                  : "question-option";
              return (
                <li
                  className={classes}
                  key={index}
                  onClick={() => handleOptionClick(index)}
                >
                  {option}
                </li>
              );
            })}
          </ol>
        </div>
      </div>
    );
  }
}

Question.propTypes = {
  currentQuestion: PropTypes.object.isRequired,
  optionSelected: PropTypes.number.isRequired,
  handleOptionClick: PropTypes.func.isRequired
};

export default Question;
