import React from "react";
import NewOption from "./NewOption";

const NewQuestion = props => {
  const questions = props.questions;
  return questions.map((val, idx) => {
    let questionId = `question-${idx}`,
      correctId = `correct-${idx}`;
    return (
      <div key={idx} className="new-question">
        <div className="subtitle">{`Question ${idx + 1}`}</div>
        <div className="field">
          <label htmlFor={questionId} className="label">
            Question
          </label>
          <div className="control">
            <textarea
              name={questionId}
              data-id={idx}
              data-type="question"
              id={questionId}
              className="textarea"
              value={questions[idx].question}
              onChange={props.handleQuestionChange}
            />
          </div>
          <p className="help">Write the question.</p>
        </div>
        <div className="subtitle">Options:</div>
        <NewOption
          question={questions[idx]}
          questionIndex={idx}
          handleChange={props.handleQuestionChange}
          removeOption={props.removeOption}
        />
        <button
          className="button is-small is-info"
          data-idx={idx}
          onClick={props.addOption}
        >
          Add New Option
        </button>
        <div className="field">
          <label htmlFor={correctId} className="label">
            Correct Answer:
          </label>
          <div className="control">
            <input
              type="number"
              name={correctId}
              data-id={idx}
              data-type="correct"
              id={correctId}
              value={questions[idx].correct}
              className="input is-rounded"
              onChange={props.handleQuestionChange}
            />
          </div>
          <p className="help">
            Enter the correct option <strong>(0:A,1:B,2:C,3:D,..)</strong>.
          </p>
          <button
            className="button is-small is-danger"
            data-idx={idx}
            onClick={props.removeQuestion}
          >
            Remove Question {idx + 1}
          </button>
        </div>
      </div>
    );
  });
};

export default NewQuestion;
