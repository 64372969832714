import React from "react";
import { withFirebase } from "../../Firebase/context";
import NewQuestion from "./NewQuestion";
import swal from "@sweetalert/with-react";
import Layout from "../../Layout";

class EditQuizBase extends React.Component {
  constructor() {
    super();
    this.state = {
      questions: [{ correct: 0, question: "", options: ["", "", "", ""] }],
      quizName: "",
      image: "",
      description: "Quiz Description",
      timeLimit: 0
    };
  }
  componentDidMount() {
    this.props.firebase.getQuiz(this.props.match.params.quizId).then(quiz => {
      if (quiz.exists) {
        const quizData = quiz.data();
        this.setState({
          questions: quizData.questions,
          quizName: quizData.quizName,
          image: quizData.image,
          description: quizData.description,
          timeLimit: quizData.timeLimit
        });
      } else {
        console.error("No such quiz!");
      }
    });
  }
  addQuestion = e => {
    e.preventDefault();
    this.setState(prevState => ({
      questions: [
        ...prevState.questions,
        { correct: 0, question: "", options: ["", "", "", ""] }
      ]
    }));
  };
  removeQuestion = e => {
    e.preventDefault();
    const questions = this.state.questions;
    const idx = e.target.dataset.idx;
    questions.splice(idx, 1);
    this.setState({
      questions
    });
  };

  addOption = e => {
    e.preventDefault();
    const questions = this.state.questions;
    const idx = e.target.dataset.idx;
    questions[idx].options.push("");
    this.setState({
      questions
    });
  };

  removeOption = e => {
    e.preventDefault();
    const questions = this.state.questions;
    const idx = e.target.dataset.idx;
    const qindex = e.target.dataset.qindex;
    questions[qindex].options.splice(idx, 1);
    this.setState({
      questions
    });
  };

  handleChange = e => {
    if ("option" === e.target.dataset.type) {
      let questions = [...this.state.questions];
      questions[e.target.dataset.qindex].options[e.target.dataset.id] =
        e.target.value;
      this.setState({ questions });
    } else if (["question", "correct"].includes(e.target.dataset.type)) {
      let questions = [...this.state.questions];
      questions[e.target.dataset.id][e.target.dataset.type] = e.target.value;
      this.setState({ questions });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.firebase
      .updateQuiz(this.props.match.params.quizId, { ...this.state })
      .then(addedQuizRef => {
        // this.setState({
        //   questions: [{ correct: 0, question: "", options: [""] }],
        //   quizName: "",
        //   image: "",
        //   description: "Quiz Description",
        //   timeLimit: 0
        // });
        swal({
          icon: "success",
          title: "Quiz Updated Successfully",
          content: (
            <div>Quiz has been updated successfully in the database.</div>
          ),
          buttons: {
            cancel: false,
            confirm: true
          }
        });
      })
      .catch(error => {
        swal({
          icon: "error",
          title: "There has been an error",
          content: (
            <div>
              The Quiz could not be saved successfully in the database. Please
              check db connection.
            </div>
          ),
          buttons: {
            cancel: false,
            confirm: true
          }
        });
        console.error("Error adding Quiz: ", error);
      });
  };

  render() {
    const { questions, quizName, image, description, timeLimit } = this.state;
    return (
      <Layout>
        <section className="section">
          <form className="container">
            <div className="field">
              <label htmlFor="quizName" className="label">
                Quiz Name
              </label>
              <div className="control">
                <input
                  type="text"
                  name="quizName"
                  id={quizName}
                  placeholder="Quiz Name"
                  value={quizName}
                  className="input is-rounded"
                  onChange={this.handleChange}
                />
              </div>
              <p className="help">Keep the quiz Name short and unique.</p>
            </div>
            <div className="field">
              <label htmlFor="image" className="label">
                Image URL
              </label>
              <div className="control">
                <input
                  type="url"
                  name="image"
                  placeholder="Image URL"
                  id={image}
                  value={image}
                  className="input is-rounded"
                  onChange={this.handleChange}
                />
              </div>
              <p className="help">
                Upload images{" "}
                <a
                  href="https://imgbb.com/upload"
                  target="_blank"
                  rel="noopener nofollow noreferrer"
                >
                  here
                </a>
                , and paste the image src above. Image should be
                square(recommended: 300px).
              </p>
            </div>
            <div className="field">
              <label htmlFor="description" className="label">
                Quiz Description
              </label>
              <div className="control">
                <textarea
                  name="description"
                  value={description}
                  id={description}
                  className="textarea"
                  onChange={this.handleChange}
                >
                  {description}
                </textarea>
              </div>
              <p className="help">Describe what the quiz is about.</p>
            </div>
            <div className="field">
              <label htmlFor="timeLimit" className="label">
                Time Limit
              </label>
              <div className="control">
                <input
                  type="number"
                  name="timeLimit"
                  placeholder="Time Limit"
                  id={timeLimit}
                  value={timeLimit}
                  className="input is-rounded"
                  onChange={this.handleChange}
                />
              </div>
              <p className="help">Please enter the time limit in seconds.</p>
            </div>
            <div className="subtitle">Questions:</div>
            <NewQuestion
              questions={questions}
              handleQuestionChange={this.handleChange}
              addOption={this.addOption}
              removeQuestion={this.removeQuestion}
              removeOption={this.removeOption}
            />
            <div className="field is-grouped is-grouped-centered">
              <p className="control">
                <button onClick={this.addQuestion} className="button is-info">
                  Add New Question
                </button>
              </p>
              <p className="control">
                <button
                  onClick={this.handleSubmit}
                  type="submit"
                  className="button is-success"
                >
                  Save Quiz
                </button>
              </p>
            </div>
          </form>
        </section>
      </Layout>
    );
  }
}

const EditQuiz = withFirebase(EditQuizBase);

export default EditQuiz;
