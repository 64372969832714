import React from "react";
import { withRouter } from "react-router-dom";
import swal from "@sweetalert/with-react";
import { withFirebase } from "../Firebase/context";

const INITIAL_STATE = {
  password: "",
  passwordVerify: "",
  error: null
};

class PasswordChangeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { password } = this.state;

    this.props.firebase
      .doPasswordUpdate(password)
      .then(() => {
        swal({
          icon: "success",
          title: "Password Changed Successfully!",
          content: (
            <div>
              Password Reset Mail has been sent successfully to your email
              address. Press yes to go back to your account.
            </div>
          ),
          buttons: {
            cancel: true,
            confirm: true
          }
        }).then(val => {
          val
            ? this.props.history.push("/account")
            : this.setState({ ...INITIAL_STATE });
        });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { password, passwordVerify, error } = this.state;

    const isInvalid = password !== passwordVerify || password === "";

    return (
      <form onSubmit={this.onSubmit}>
        <div className="field">
          <p className="control has-icons-left">
            <input
              name="password"
              className="input"
              type="password"
              value={password}
              onChange={this.onChange}
              placeholder="Password"
            />
            <span className="icon is-small is-left">
              <i className="fas fa-lock" />
            </span>
          </p>
        </div>
        <div className="field">
          <p className="control has-icons-left">
            <input
              name="passwordVerify"
              className="input"
              type="password"
              value={passwordVerify}
              onChange={this.onChange}
              placeholder="Confirm New Password"
            />
            <span className="icon is-small is-left">
              <i className="fas fa-lock" />
            </span>
          </p>
        </div>
        <button disabled={isInvalid} type="submit">
          Reset My Password
        </button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const ChangePassword = withRouter(withFirebase(PasswordChangeForm));

export default ChangePassword;
