import React from "react";

const NewOption = props => {
  const options = props.question.options;
  return options.map((val, idx) => {
    let optionId = `option-${idx}`;
    return (
      <div key={idx} className="new-option">
        <div className="field">
          <label htmlFor={optionId} className="label">
            {`Option ${String.fromCharCode(65 + idx)}(${idx})`}
          </label>
          <div className="control">
            <input
              type="text"
              name={optionId}
              data-id={idx}
              data-qindex={props.questionIndex}
              data-type="option"
              id={optionId}
              value={props.question.options[idx]}
              className="option input is-rounded"
              onChange={props.handleChange}
            />
            <button
              data-id={idx}
              data-qindex={props.questionIndex}
              onClick={props.removeOption}
              className="button is-danger is-small"
            >
              Remove Option
            </button>
          </div>
        </div>
      </div>
    );
  });
};

export default NewOption;
