import React from "react";
import { Link } from "react-router-dom";
import { withFirebase } from "./Firebase/context";
import AuthUserContext from "./Session/context";
import LogOutButton from "./Account/LogOut";

class SiteHeaderBase extends React.Component {
  constructor() {
    super();
    this.state = {
      isActive: false
    };
  }

  toggleMenu = () => {
    this.setState({
      isActive: !this.state.isActive
    });
  };

  render() {
    return (
      <header className="has-background-white">
        <nav className="level is-mobile">
          <div className="level-left">
            <div className="level-item">
              <p className="subtitle is-7">स्वागतम्</p>
            </div>
          </div>

          <div className="level-right">
            <p className="level-item">
              <a
                href="https://facebook.com/sugamgyan/"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                <span className="icon has-text-primary">
                  <i className="fab fa-facebook" />
                </span>
              </a>
            </p>
            <p className="level-item">
              <a
                href="https://twitter.com/sugamgyan/"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                <span className="icon has-text-primary">
                  <i className="fab fa-twitter" />
                </span>
              </a>
            </p>
            <p className="level-item">
              <a
                href="https://www.instagram.com/sugamgyan/"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                <span className="icon has-text-primary">
                  <i className="fab fa-instagram" />
                </span>
              </a>
            </p>
            <p className="level-item">
              <a
                href="https://www.youtube.com/c/Sugamgyan"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                <span className="icon has-text-primary">
                  <i className="fab fa-youtube" />
                </span>
              </a>
            </p>
          </div>
        </nav>
        <hr className="line" />
        <div className="container">
          <a href="/">
            <img
              className="image is-centered"
              src="https://sugamgyan.com/wp-content/uploads/2018/03/sugamgyan-website-logo.png"
              width="100"
              alt="logo"
            />
          </a>
        </div>
        <nav
          className="navbar has-background-primary has-text-white is-small"
          role="navigation"
          aria-label="main navigation"
        >
          <div className="navbar-brand">
            <span
              role="button"
              className={
                this.state.isActive
                  ? `navbar-burger burger is-active has-text-white`
                  : `navbar-burger burger has-text-white`
              }
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarBasicExample"
              onClick={this.toggleMenu}
            >
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </span>
          </div>

          <div
            id="mainNav"
            className={
              this.state.isActive ? `navbar-menu is-active` : `navbar-menu`
            }
          >
            <AuthUserContext.Consumer>
              {authUser =>
                authUser ? <LoggedInMenu user={authUser} /> : <DefaultMenu />
              }
            </AuthUserContext.Consumer>
          </div>
        </nav>
      </header>
    );
  }
}

const LoggedInMenu = props => (
  <React.Fragment>
    <div className="navbar-start has-background-primary">
      <Link to="/admin/quizzes" className="navbar-item">
        All Quizzes
      </Link>

      <Link to="/admin/quizzes/new" className="navbar-item">
        Add Quiz
      </Link>
    </div>
    <div className="navbar-end">
      <div className="navbar-item">
        <div className="navbar-item">{`Welcome ${props.user.email}`}</div>
        <LogOutButton />
      </div>
    </div>
  </React.Fragment>
);

const DefaultMenu = props => (
  <div className="navbar-start has-background-primary is-centered">
    <a className="navbar-item" href="https://sugamgyan.com/category/hindi/">
      हिंदी
    </a>
    <a className="navbar-item" href="https://sugamgyan.com/category/english/">
      अंग्रेजी
    </a>
    <a className="navbar-item" href="https://sugamgyan.com/current-affairs/">
      समसामयिकी
    </a>
    <a
      href="https://sugamgyan.com/category/mathematics/"
      className="navbar-item"
    >
      गणित
    </a>
    <a href="https://sugamgyan.com/category/computer/" className="navbar-item">
      कम्प्यूटर
    </a>
    <a
      href="https://sugamgyan.com/category/general-knowledge/"
      className="navbar-item"
    >
      सामान्य अध्ययन
    </a>
    <a
      href="https://sugamgyan.com/category/competitive-exams/"
      className="navbar-item"
    >
      प्रतियोगी परीक्षायें
    </a>

    <a href="https://sugamgyan.com/category/interview/" className="navbar-item">
      साक्षात्कार
    </a>

    <a
      href="https://sugamgyan.com/category/great-personalities/"
      className="navbar-item"
    >
      महान व्यक्तित्व
    </a>

    <a
      href="https://sugamgyan.com/category/interesting-information/"
      className="navbar-item"
    >
      रोचक जानकारी
    </a>
    <a href="https://sugamgyan.com/contact-us/" className="navbar-item">
      सम्पर्क सूत्र
    </a>
  </div>
);

const SiteHeader = withFirebase(SiteHeaderBase);

export default SiteHeader;
