import React from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import { withFirebase } from "../../Firebase/context";
import withAuthorization from "../../Session/withAuthorization";
import AuthUserContext from "../../Session/context";
import swal from "@sweetalert/with-react";
import Layout from "../../Layout";

class QuizAdminBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quizzes: [],
      quizzesData: {},
      selectedQuiz: ""
    };
  }

  getQuizzes = () => {
    const Quizzes = {};
    this.props.firebase
      .getAllQuizzes()
      .then(snapshot => {
        snapshot.forEach(quiz => {
          Quizzes[quiz.id] = quiz.data();
        });
        this.setState({
          quizzes: Object.keys(Quizzes),
          quizzesData: Quizzes,
          selectedQuiz: Object.keys(Quizzes)[0]
        });
      })
      .catch(err => {
        console.error("Error getting quizzes", err);
      });
  };

  componentDidMount() {
    this.getQuizzes();
  }

  deleteQuiz(id) {
    swal({
      icon: "warning",
      title: "Delete Quiz",
      content: <div>Are you sure you want to delete the quiz?</div>,
      buttons: {
        cancel: true,
        confirm: true
      }
    }).then(value => {
      if (value === true) {
        this.props.firebase
          .deleteQuiz(id)
          .then(() => {
            swal({
              icon: "success",
              title: "Quiz successfully deleted!",
              content: (
                <div>Quiz has been deleted successfully from the datbase.</div>
              ),
              buttons: {
                cancel: false,
                confirm: true
              }
            }).then(() => this.getQuizzes());
          })
          .catch(error => {
            alert("Error deleting quiz: ", error);
            console.error(error);
          });
      }
    });
  }

  handleChange = selectedQuiz =>
    this.setState({ selectedQuiz: selectedQuiz.value });

  editQuiz = event => {
    // 1. Stop the form from submitting
    event.preventDefault();
    // 2. get the selected quiz from the state
    const quizId = this.state.selectedQuiz;
    // 3. Change the page to /quiz/whatever-they-entered
    this.props.history.push(`/admin/quizzes/edit/${quizId}`);
  };

  generateAvailableQuizRows = (quiz, index) => {
    const data = this.state.quizzesData[quiz];
    return (
      <tr key={index} className="tr">
        <th>{quiz}</th>
        <td>{data.quizName}</td>
        <td>{data.questions.length}</td>
        <td>
          <Link className="button is-primary is-rounded" to={"/quiz/" + quiz}>
            Start
          </Link>
        </td>
        <td>
          <Link
            className="button is-primary is-rounded"
            to={"/admin/quizzes/edit/" + quiz}
          >
            Edit
          </Link>
        </td>
        <td>
          <button
            className="button is-danger is-rounded"
            onClick={() => this.deleteQuiz(quiz)}
          >
            Delete
          </button>
        </td>
      </tr>
    );
  };

  render() {
    const availableQuizzes = this.state.quizzes.map(quiz => ({
      value: quiz,
      label: this.state.quizzesData[quiz].quizName
    }));
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <Layout>
            <section className="hero is-medium is-primary is-bold">
              <div className="hero-body">
                <div className="container">
                  <h1 className="title has-text-centered">Quiz Application</h1>
                  <h2 className="subtitle has-text-centered">
                    Edit or Delete Quizzes
                  </h2>
                </div>
              </div>
            </section>
            <section className="section">
              <form onSubmit={this.editQuiz}>
                <h3 className="is-size-2 has-text-black has-text-centered has-text-weight-semibold">
                  Select which quiz you want to edit :
                </h3>
                <div className="columns">
                  <div className="column">
                    <div className="quiz-picker is-9">
                      <Select
                        onChange={this.handleChange}
                        options={availableQuizzes}
                        noOptionsMessage={() =>
                          "Sorry! No quiz found for your search term"
                        }
                        required
                      >
                        {availableQuizzes}
                      </Select>
                    </div>
                  </div>
                  <div className="column is-3">
                    <button
                      className="button is-centered is-rounded is-secondary is-medium"
                      type="submit"
                    >
                      Edit Quiz
                    </button>
                  </div>
                </div>
              </form>
            </section>
            <section className="section">
              <div className="title">
                <h2 className="is-size-2 has-text-black has-text-weight-semibold">
                  All Available Quizzes
                </h2>
                <Link to="/admin/quizzes/new">Add a new Quiz</Link>
              </div>
              <table className="table">
                <thead>
                  <tr>
                    <th>Quiz Id</th>
                    <th>Quiz Name</th>
                    <th>No. of Questions</th>
                    <th>Start Quiz</th>
                    <th>Edit Quiz</th>
                    <th>Delete Quiz</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.quizzes.length === 0 ? (
                    <tr>
                      <td />
                      <td />
                      <th>Fetching Data . Please wait!</th>
                      <td />
                      <td />
                    </tr>
                  ) : (
                    this.state.quizzes.map((quiz, index) =>
                      this.generateAvailableQuizRows(quiz, index)
                    )
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <th>Quiz Id</th>
                    <th>Quiz Name</th>
                    <th>No. of Questions</th>
                    <th>Start Quiz</th>
                    <th>Edit Quiz</th>
                    <th>Delete Quiz</th>
                  </tr>
                </tfoot>
              </table>
            </section>
          </Layout>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = authUser => !!authUser;

const QuizAdmin = withAuthorization(condition)(withFirebase(QuizAdminBase));

export default QuizAdmin;
