import React from "react";
import PropTypes from "prop-types";
import Header from "./Header";
import Question from "./Question";
import Footer from "./Footer";
import Results from "./Results";
import { withFirebase } from "../../Firebase/context";

class QuizAppBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...this.getInitialState() };
  }
  getInitialState = () => {
    return {
      quizName: "",
      questions: [],
      totalQuestions: 0,
      timeRemaining: 0,
      timeLimit: 0,
      isRunning: true,
      userAnswers: [],
      currentQuestionIndex: 0,
      currentQuestion: {},
      showResult: 0,
      showSpinner: true
    };
  };

  componentDidMount() {
    this.props.firebase
      .getQuiz(this.props.match.params.quizId)
      .then(quiz => {
        if (quiz.exists) {
          const { quizName, timeLimit, questions } = quiz.data();
          const totalQuestions = questions.length;
          const timeRemaining = Number(timeLimit);
          const userAnswers = questions.map(() => -1);
          // Encrypt Correct Answer data in questions
          questions.forEach(question => {
            question.correct = btoa(question.correct);
          });
          this.setState({
            quizName,
            questions,
            totalQuestions,
            timeRemaining,
            timeLimit: Number(timeLimit),
            userAnswers,
            currentQuestion: questions[0],
            showResult: 0,
            showSpinner: false
          });
          if (this.state.isRunning === true) this.runClock();
        } else {
          this.props.history.push(`/no-quiz-found`);
        }
      })
      .catch(err => {
        console.error("Error getting quizzes", err);
      });
  }

  runClock = () => {
    let temp;
    let { isRunning, timeRemaining, showResult } = this.state;
    if (isRunning && !showResult && timeRemaining > 0) {
      this.setState({ timeRemaining: timeRemaining - 1 });
      temp = window.setTimeout(this.runClock, 1000);
    } else {
      this.setState({ isRunning: false, showResult: 1 });
      clearTimeout(temp);
    }
  };

  handleOptionClick = userChoice => {
    const { userAnswers, currentQuestionIndex } = this.state;
    //Save User Answer
    userAnswers[currentQuestionIndex] = userChoice;
    this.setState({
      userAnswers
    });
  };

  goToQuestion = questionNumber => {
    let { questions, currentQuestionIndex, currentQuestion } = this.state;
    currentQuestionIndex = questionNumber;
    currentQuestion = questions[currentQuestionIndex];
    this.setState({
      questions,
      currentQuestionIndex,
      currentQuestion
    });
  };

  submitQuiz = () => {
    this.setState({
      showResult: 1
    });
  };

  restartQuiz = () => {
    this.setState({ ...this.getInitialState() });
  };

  render() {
    const {
      quizName,
      questions,
      totalQuestions,
      timeLimit,
      timeRemaining,
      userAnswers,
      currentQuestionIndex,
      currentQuestion
    } = this.state;
    if (this.state.showSpinner) {
      return (
        <section className="hero is-fullheight">
          <div className="hero-body">
            <div
              className="container is-fullhd"
              style={{ flexGrow: "0", justifyItems: "center" }}
            >
              <div className="loader-inner ball-scale-multiple">
                <div style={{ backgroundColor: "#1bbc9c" }} />
                <div style={{ backgroundColor: "#1bbc9c" }} />
                <div style={{ backgroundColor: "#1bbc9c" }} />
              </div>
            </div>
          </div>
        </section>
      );
    } else {
      if (this.state.showResult === 1) {
        return (
          <Results
            quizId={this.props.match.params.quizId}
            questions={questions}
            quizName={quizName}
            userAnswers={userAnswers}
          />
        );
      } else {
        return (
          <React.Fragment>
            <Header
              quizName={quizName}
              timeRemaining={timeRemaining}
              timeLimit={timeLimit}
              totalQuestions={totalQuestions}
              currentQuestionIndex={currentQuestionIndex}
            />
            <Question
              currentQuestion={currentQuestion}
              optionSelected={userAnswers[currentQuestionIndex]}
              handleOptionClick={this.handleOptionClick}
            />
            <Footer
              goToQuestion={this.goToQuestion}
              totalQuestions={totalQuestions}
              currentQuestionIndex={currentQuestionIndex}
              userAnswers={userAnswers}
              submitQuiz={this.submitQuiz}
            />
          </React.Fragment>
        );
      }
    }
  }
}

const QuizApp = withFirebase(QuizAppBase);

QuizApp.propTypes = {
  match: PropTypes.object
};

export default QuizApp;
