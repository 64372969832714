import React from "react";
import { Link } from "react-router-dom";
const QuizNotFound = () => (
  <div>
    <section className="hero is-fullheight is-danger">
      <div className="hero-body">
        <h2 className="title">
          Error : Quiz Not Found! Please proceed back to the All Quizzes page.
        </h2>
        <br />
        <div class="subtitle">
          <Link to={"/"} class="button is-primary">
            सभी उपलब्ध टेस्ट
          </Link>
        </div>
      </div>
    </section>
  </div>
);

export default QuizNotFound;
