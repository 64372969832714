import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import withAuthentication from "./Session/withAuthentication";
import withTracker from "./withTracker";
import Home from "./App/Home";
import QuizApp from "./App/Quiz/QuizApp";
import NewQuiz from "./Admin/Quizzes/NewQuiz";
import NotFound from "./NotFound";
import QuizNotFound from "./App/QuizNotFound";
import EditQuiz from "./Admin/Quizzes/EditQuiz";
import QuizAdmin from "./Admin/Quizzes/QuizAdmin";
// import Register from "./Account/Register";
import Login from "./Account/Login";
import ChangePassword from "./Account/ChangePassword";
import ForgotPassword from "./Account/ForgotPassword";
import UserAdmin from "./Admin/Users/UserAdmin";

const Router = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={withTracker(Home)} />
      <Route exact path="/admin/quizzes" component={QuizAdmin} />
      <Route path="/admin/quizzes/edit/:quizId" component={EditQuiz} />
      <Route exact path="/admin/quizzes/new" component={NewQuiz} />
      <Route path="/quiz/:quizId" component={withTracker(QuizApp)} />
      <Route
        exact
        path="/no-quiz-found"
        component={withTracker(QuizNotFound)}
      />
      <Route exact path="/admin/users" component={UserAdmin} />
      {/*  Also update the quiz path to quizzes
      <Route path="/admin/users/edit/:userId" component={} />
      <Route exact path="/admin/users/new" component={} />
      <Route path="/users/:userId" component={} />
      <Route exact path="/no-user-found" component={} />
      Registration is switched off
      <Route exact path="/account/register" component={Register} /> */}
      <Route exact path="/account/change-password" component={ChangePassword} />
      <Route exact path="/account/forgot-password" component={ForgotPassword} />
      <Route exact path="/account/login" component={Login} />
      <Route component={NotFound} />
    </Switch>
  </BrowserRouter>
);

export default withAuthentication(Router);
