import React from "react";
import PropTypes from "prop-types";

class Header extends React.Component {
  render() {
    const {
      quizName,
      timeLimit,
      totalQuestions,
      currentQuestionIndex,
      timeRemaining
    } = this.props;
    return (
      <header className="section has-background-white">
        <div className="container is-fluid">
          <div>
            <h1 className="title has-text-centered has-text-primary is-size-1">
              {quizName}
            </h1>
          </div>
        </div>
        <div className="container is-clearfix ">
          <div className="is-pulled-left">
            <div className="subtitle has-text-centered has-text-grey">
              <strong>{totalQuestions}</strong> प्रश्नों में से प्रश्न{" "}
              <strong>{currentQuestionIndex + 1}</strong>
            </div>
          </div>
          <div className="is-pulled-right">
            शेष समय:{" "}
            {timeRemaining < 101
              ? ` ${timeRemaining} सेकंड`
              : ` ${Math.floor(timeRemaining / 60)} मिनट`}
            {/* Time Left: {timeRemaining}/{timeLimit} seconds */}
            <progress
              className="progress is-small"
              value={timeRemaining}
              max={timeLimit}
            >
              {timeRemaining}
            </progress>
          </div>
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  quizName: PropTypes.string.isRequired,
  timeRemaining: PropTypes.number.isRequired,
  timeLimit: PropTypes.number.isRequired,
  totalQuestions: PropTypes.number.isRequired,
  currentQuestionIndex: PropTypes.number.isRequired
};

export default Header;
