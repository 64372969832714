import React from "react";
import Router from "./components/Router";
import { render } from "react-dom";
import Firebase from "./components/Firebase/Firebase";
import FirebaseContext from "./components/Firebase/context";
import * as serviceWorker from "./serviceWorker";
import "./index.css";

render(
  <FirebaseContext.Provider value={new Firebase()}>
    <Router />
  </FirebaseContext.Provider>,
  document.querySelector("#main")
);

serviceWorker.register();
