import React from "react";
import SiteHeader from "./SiteHeader";

const Layout = ({ children }) => (
  <React.Fragment>
    <SiteHeader />
    {children}
  </React.Fragment>
);

export default Layout;
