import React from "react";
import { Link, withRouter } from "react-router-dom";
import { withFirebase } from "../Firebase/context";

const Login = () => (
  <div id="login">
    <div className="login-card">
      <div className="card-title">
        <h1>Sign In</h1>
      </div>

      <div className="content">
        <LoginForm />
      </div>
      <div>
        {/* <Link to="/account/register">Don't have an account ?</Link> */}
        <Link to="/account/forgot">Forgot Password ?</Link>
      </div>
    </div>
  </div>
);

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null
};

class LoginFormBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(data => {
        this.setState({ ...INITIAL_STATE });
        console.log(data);
        this.props.history.push("/admin/quizzes");
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === "" || email === "";

    return (
      <form onSubmit={this.onSubmit}>
        <div className="field">
          <p className="control has-icons-left has-icons-right">
            <input
              name="email"
              className="input"
              type="email"
              value={email}
              onChange={this.onChange}
              placeholder="Email"
            />
            <span className="icon is-small is-left">
              <i className="fas fa-envelope" />
            </span>
            <span className="icon is-small is-right">
              <i className="fas fa-check" />
            </span>
          </p>
        </div>
        <div className="field">
          <p className="control has-icons-left">
            <input
              name="password"
              className="input"
              type="password"
              value={password}
              onChange={this.onChange}
              placeholder="Password"
            />
            <span className="icon is-small is-left">
              <i className="fas fa-lock" />
            </span>
          </p>
        </div>
        <div className="field">
          <p className="control">
            <button
              disabled={isInvalid}
              type="submit"
              className="button is-success"
            >
              Login
            </button>
          </p>
        </div>
        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const LoginForm = withRouter(withFirebase(LoginFormBase));

export default Login;

export { LoginForm };
