import React from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import { withFirebase } from "../../Firebase/context";
import withAuthorization from "../../Session/withAuthorization";
import AuthUserContext from "../../Session/context";
import swal from "@sweetalert/with-react";
import Layout from "../../Layout";

class UserAdminBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      usersData: {},
      selectedUser: ""
    };
  }

  getUsers = () => {
    const Users = {};
    this.props.firebase
      .getAllUsers()
      .then(snapshot => {
        snapshot.forEach(user => {
          Users[user.id] = user.data();
        });
        this.setState({
          users: Object.keys(Users),
          usersData: Users,
          selectedUser: Object.keys(Users)[0]
        });
      })
      .catch(err => {
        console.error("Error getting users", err);
      });
  };

  componentDidMount() {
    this.getUsers();
  }

  deleteUser(id) {
    swal({
      icon: "warning",
      title: "Delete User",
      content: <div>Are you sure you want to delete this user?</div>,
      buttons: {
        cancel: true,
        confirm: true
      }
    }).then(value => {
      if (value === true) {
        this.props.firebase
          .deleteUser(id)
          .then(() => {
            swal({
              icon: "success",
              title: "User successfully deleted!",
              content: (
                <div>User has been deleted successfully from the database.</div>
              ),
              buttons: {
                cancel: false,
                confirm: true
              }
            }).then(() => this.getUsers());
          })
          .catch(error => {
            alert("Error deleting user: ", error);
            console.error(error);
          });
      }
    });
  }

  handleChange = selectedUser =>
    this.setState({ selectedUser: selectedUser.value });

  editUser = event => {
    // 1. Stop the form from submitting
    event.preventDefault();
    // 2. get the selected quiz from the state
    const userId = this.state.selectedUser;
    // 3. Change the page to /quiz/whatever-they-entered
    this.props.history.push(`/admin/users/edit/${userId}`);
  };

  generateAvailableUserRows = (user, index) => {
    const data = this.state.usersData[user];
    return (
      <tr key={index} className="tr">
        <th>{user}</th>
        <td>{data.displayName}</td>
        <td>{data.email}</td>
        <td>
          <Link className="button is-primary is-rounded" to={"/user/" + user}>
            View
          </Link>
        </td>
        <td>
          <Link
            className="button is-primary is-rounded"
            to={"/admin/users/edit/" + user}
          >
            Edit
          </Link>
        </td>
        <td>
          <button
            className="button is-danger is-rounded"
            onClick={() => this.deleteUser(user)}
          >
            Delete
          </button>
        </td>
      </tr>
    );
  };

  render() {
    const availableUsers = this.state.users.map(user => ({
      value: user,
      label: this.state.usersData[user].displayName
    }));
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <Layout>
            <section className="hero is-medium is-primary is-bold">
              <div className="hero-body">
                <div className="container">
                  <h1 className="title has-text-centered">Quiz Application</h1>
                  <h2 className="subtitle has-text-centered">
                    Edit or Delete Users
                  </h2>
                </div>
              </div>
            </section>
            <section className="section">
              <form onSubmit={this.editUser}>
                <h3 className="is-size-2 has-text-black has-text-centered has-text-weight-semibold">
                  Select which user you want to edit :
                </h3>
                <div className="columns">
                  <div className="column">
                    <div className="quiz-picker is-9">
                      <Select
                        onChange={this.handleChange}
                        options={availableUsers}
                        noOptionsMessage={() =>
                          "Sorry! No quiz found for your search term"
                        }
                        required
                      >
                        {availableUsers}
                      </Select>
                    </div>
                  </div>
                  <div className="column is-3">
                    <button
                      className="button is-centered is-rounded is-secondary is-medium"
                      type="submit"
                    >
                      Edit User
                    </button>
                  </div>
                </div>
              </form>
            </section>
            <section className="section">
              <div className="title">
                <h2 className="is-size-2 has-text-black has-text-weight-semibold">
                  All Available Users
                </h2>
                <Link to="/admin/quizzes/new">Add a new User</Link>
              </div>
              <table className="table">
                <thead>
                  <tr>
                    <th>User Id</th>
                    <th>User Name</th>
                    <th>User Email</th>
                    <th>View User</th>
                    <th>Edit User</th>
                    <th>Delete User</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.users.length === 0 ? (
                    <tr>
                      <td />
                      <td />
                      <th>Fetching Data . Please wait!</th>
                      <td />
                      <td />
                    </tr>
                  ) : (
                    this.state.users.map((user, index) =>
                      this.generateAvailableUserRows(user, index)
                    )
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <th>User Id</th>
                    <th>User Name</th>
                    <th>User Email</th>
                    <th>View User</th>
                    <th>Edit User</th>
                    <th>Delete User</th>
                  </tr>
                </tfoot>
              </table>
            </section>
          </Layout>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

const condition = authUser => !!authUser;

const UserAdmin = withAuthorization(condition)(withFirebase(UserAdminBase));

export default UserAdmin;
