import React from "react";
import PropTypes from "prop-types";
import Layout from "../../Layout";
import { withRouter } from "react-router-dom";

class Results extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeModal: -1
    };
  }

  decodeAnswer = encodedAnswer => {
    return Number(atob(encodedAnswer));
  };

  answerCodeToCharacter = answerCode => {
    return String.fromCharCode(65 + Number(answerCode));
  };

  // generateModals = () => {
  //   const questions = this.props.questions;
  //   const modals = this.props.questions.map((answer, questionIndex) => {
  //     //Decode the correct answer
  //     const correct = this.answerCodeToCharacter(
  //       this.decodeAnswer(questions[questionIndex].correct)
  //     );
  //     return (
  //       <div
  //         key={questionIndex}
  //         className={
  //           this.state.activeModal === questionIndex
  //             ? `modal is-active`
  //             : `modal`
  //         }
  //       >
  //         <div className="modal-background has-background-light" />
  //         <div className="modal-content ">
  //           <div className="content">
  //             <p>{questions[questionIndex].question}</p>
  //             <ol type="A">
  //               {questions[questionIndex].options.map((option, index) => {
  //                 return <li key={index}>{option}</li>;
  //               })}
  //             </ol>
  //             <span className="tag is-primary is-medium">
  //               सही उत्तर : {correct}
  //             </span>
  //           </div>
  //           <button
  //             className="modal-close is-large has-background-black"
  //             aria-label="close"
  //             onClick={this.hideModal}
  //           />
  //         </div>
  //       </div>
  //     );
  //   });
  //   return modals;
  // };

  generateAnswerSheet = () => {
    const questions = this.props.questions;
    const answerSheet = this.props.userAnswers.map((answer, index) => {
      //Decode the correct answer
      const correct = this.answerCodeToCharacter(
        this.decodeAnswer(questions[index].correct)
      );
      answer = answer === -1 ? "छोड़ दिया" : this.answerCodeToCharacter(answer);
      return (
        <tr
          key={index}
          className={
            correct === answer ? "has-background-success has-text-white" : ""
          }
          // onClick={this.showModal.bind(this, index)}
        >
          <th>{index + 1}</th>
          <td>
            {/* {questions[index].question.length > 50
              ? `${questions[index].question.slice(0, 50)}...`
              : questions[index].question} */}
            {questions[index].question}
            <ol type="A">
              {questions[index].options.map((option, index) => {
                return <li key={index}>{option}</li>;
              })}
            </ol>
          </td>
          <td>{correct}</td>
          <td>{answer}</td>
        </tr>
      );
    });
    return answerSheet;
  };

  // showModal = questionIndex => {
  //   this.setState({
  //     activeModal: questionIndex
  //   });
  // };

  // hideModal = questionIndex => {
  //   this.setState({
  //     activeModal: -1
  //   });
  // };

  generateResult = () => {
    const { questions, userAnswers } = this.props;
    const result = {};
    const correct = questions.map(question =>
      this.decodeAnswer(question.correct)
    );
    result.correct = userAnswers.reduce((score, answer, index) => {
      if (answer === correct[index]) return score + 1;
      else return score;
    }, 0);
    result.wrong = userAnswers.reduce((score, answer, index) => {
      if (answer !== correct[index] && answer !== -1) return score + 1;
      else return score;
    }, 0);
    result.skipped = userAnswers.reduce((score, answer, index) => {
      if (answer === -1) return score + 1;
      else return score;
    }, 0);
    return result;
  };

  render() {
    const score = this.generateResult();
    return (
      <Layout>
        <div className="container is-widescreen has-background-primary has-text-centered has-text-white">
          <h2 className="title has-text-white">टेस्ट परिणाम</h2>
          <h3 className="subtitle has-text-white">{this.props.quizName}</h3>
          <div className="score">
            सही उत्तर : {score.correct}
            <br />
            गलत उत्तर : {score.wrong}
            <br />
            छोड़े हुए : {score.skipped}
            <br />
          </div>
          {/* AddToAny BEGIN  */}
          <div>
            <a
              href="https://www.addtoany.com/share#url=https%3A%2F%2Fexam.sugamgyan.com&amp;title="
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://static.addtoany.com/buttons/a2a.svg"
                alt="AddToAny Sharing Button"
                width="32"
                height="32"
                // style="background-color:royalblue"
              />
            </a>
            <a
              href="https://www.addtoany.com/add_to/whatsapp?linkurl=https%3A%2F%2Fexam.sugamgyan.com&amp;linkname="
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://static.addtoany.com/buttons/whatsapp.svg"
                alt="WhatsApp Sharing Button"
                width="32"
                height="32"
                // style="background-color:royalblue"
              />
            </a>
            <a
              href="https://www.addtoany.com/add_to/facebook_messenger?linkurl=https%3A%2F%2Fexam.sugamgyan.com&amp;linkname="
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://static.addtoany.com/buttons/facebook_messenger.svg"
                alt="Facebook Messenger Sharing Button"
                width="32"
                height="32"
                // style="background-color:royalblue"
              />
            </a>
            <a
              href="https://www.addtoany.com/add_to/facebook?linkurl=https%3A%2F%2Fexam.sugamgyan.com&amp;linkname="
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://static.addtoany.com/buttons/facebook.svg"
                alt="Facebook Sharing Button"
                width="32"
                height="32"
                // style="background-color:royalblue"
              />
            </a>
            <a
              href="https://www.addtoany.com/add_to/telegram?linkurl=https%3A%2F%2Fexam.sugamgyan.com&amp;linkname="
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://static.addtoany.com/buttons/telegram.svg"
                alt="Telegram Sharing Button"
                width="32"
                height="32"
                // style="background-color:royalblue"
              />
            </a>
          </div>
          {/* <!-- AddToAny END --> */}
          <div className="buttons is-centered">
            <button
              className="button"
              onClick={() => this.props.history.push("/")}
            >
              सभी उपलब्ध टेस्ट
            </button>
            <button className="button" onClick={() => window.location.reload()}>
              पुनः प्रयास करें
            </button>
          </div>
        </div>

        <div className="container" style={{ padding: "2rem 0" }}>
          <table className="table is-centered is-hoverable">
            <thead>
              <tr>
                <th>
                  <abbr title="Question Number">संख्या</abbr>
                </th>
                <th>
                  <abbr title="Question[Short]">प्रश्न </abbr>
                </th>
                <th>
                  <abbr title="Correct Answer">सही उत्तर </abbr>
                </th>
                <th>
                  <abbr title="Your Answer">उत्तर दिया गए</abbr>
                </th>
              </tr>
            </thead>
            <tbody>{this.generateAnswerSheet()}</tbody>
          </table>
          {/* {this.generateModals()} */}
        </div>
      </Layout>
    );
  }
}

Results.propTypes = {
  userAnswers: PropTypes.array.isRequired,
  quizName: PropTypes.string.isRequired,
  questions: PropTypes.array.isRequired
};

export default withRouter(Results);
